import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import TextContainer from 'components/common/text-container'
import Hero from 'components/hero'
import Layout from 'components/layout'
import { SubHeading, Heading } from 'components/common/typography'
import ServiceImage from 'components/services/preview-image'
import ContentfulText from 'components/contentful-text'
import NavButton from 'components/common/nav-button'

const Headshot = styled(Img)`
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
  margin-top: 8px;
`

const SecondaryServices = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const PrimaryServices = styled.div``

const PrimaryService = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Service = styled.div`
  display: block;
`

const ServiceTitle = styled.h3`
  margin-top: 8px;
  margin-bottom: 8px;
`

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
`

const IndexPage = ({ data }) => (
  <Layout>
    <Hero file={data.heroImage} />
    <Helmet>
      <script src='https://cdn.lightwidget.com/widgets/lightwidget.js'></script>
    </Helmet>

    <TextContainer>
      <Heading>Toronto Modern Calligraphy</Heading>
      <p as='h2'>
        <ContentfulText textJson={data.tagLine.text.json} />
      </p>
    </TextContainer>
    <TextContainer>
      <Heading>Featured Service</Heading>
      <PrimaryServices>
        {data.primaryServices.edges.map(({ node: service }) => (
          <Service key={service.portfolioCategorySlug}>
            <PrimaryService>
              <Link to={`/portfolio/${service.portfolioCategorySlug}`}>
                <ServiceImage alt={service.label} asset={service.image} />
              </Link>
              <div>
                <Link to={`/portfolio/${service.portfolioCategorySlug}`}>
                  <ServiceTitle>{service.label}</ServiceTitle>
                </Link>
                <ContentfulText textJson={service.description.json} />
              </div>
            </PrimaryService>
          </Service>
        ))}
      </PrimaryServices>
    </TextContainer>
    <TextContainer>
      <Heading>Other Services</Heading>
      <SecondaryServices>
        {data.secondaryServices.edges.map(({ node: service }) => (
          <Service key={service.portfolioCategorySlug}>
            <Link to={`/portfolio/${service.portfolioCategorySlug}`}>
              <ServiceImage alt={service.label} asset={service.image} />
              <ServiceTitle>{service.label}</ServiceTitle>
            </Link>
            <ContentfulText textJson={service.description.json} />
          </Service>
        ))}
      </SecondaryServices>
      <Center>
        <Link to='/portfolio'>
          <NavButton invert border>
            See full portfolio
          </NavButton>
        </Link>
      </Center>
    </TextContainer>
    {/* <Subscription /> */}
    {/* <TextContainer>
      <SubHeading>Check me out on Instagram!</SubHeading>
      <InstagramFeed />
    </TextContainer> */}
    <TextContainer>
      <SubHeading>Behind the Craft</SubHeading>
      <Headshot alt='Jovie Galit' fixed={data.headShotImage.childImageSharp.fixed} />
      <ContentfulText textJson={data.aboutMe.text.json} />
    </TextContainer>
  </Layout>
)

export const query = graphql`
  query IndexPageQuery {
    heroImage: file(name: { eq: "hero1" }) {
      ...HeroFile
    }

    aboutMe: contentfulCopy(key: { eq: "aboutMe" }) {
      text {
        json
      }
    }

    tagLine: contentfulCopy(key: { eq: "tagLine" }) {
      text {
        json
      }
    }

    primaryServices: allContentfulService(
      sort: { fields: order, order: ASC }
      filter: { primary: { eq: true } }
    ) {
      ...Services
    }

    secondaryServices: allContentfulService(
      sort: { fields: order, order: ASC }
      filter: { primary: { eq: false } }
    ) {
      ...Services
    }

    headShotImage: file(name: { eq: "head-shot" }) {
      id

      childImageSharp {
        fixed(width: 250, height: 300, cropFocus: CENTER, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export const servicesFragment = graphql`
  fragment Services on ContentfulServiceConnection {
    edges {
      node {
        id
        label
        description {
          json
        }
        portfolioCategorySlug

        image {
          ...ServicePreviewImageContentfulAsset
        }
      }
    }
  }
`

export default IndexPage
