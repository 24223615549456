import React from 'react'
import { Link } from 'gatsby'
import { INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const ContentfulLink = (node, children) =>
  node.data.uri.indexOf('www') === -1 && node.data.uri.indexOf('http') === -1 ? (
    <Link to={node.data.uri}>{children}</Link>
  ) : (
    <a href={node.data.uri}>{children}</a>
  )

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ContentfulLink,
  },
}

export default function ContentfulText({ textJson }) {
  return documentToReactComponents(textJson, options)
}
