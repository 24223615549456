import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

export default function ServicePreviewImage({ asset, alt }) {
  return <Img alt={alt} fluid={asset.fluid} />
}

export const fragment = graphql`
  fragment ServicePreviewImageContentfulAsset on ContentfulAsset {
    id

    fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
      ...GatsbyContentfulFluid_withWebp
    }
  }
`
